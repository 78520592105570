import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { languages } from '../../utils/languages';

const LanguagePickerContainer = styled.div`
  position: relative;
  display: inline-block;

  .icon-button {
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .globe-icon {
    width: 20px;
    height: 20px;
    stroke: #ffffff; /* Default color (white) */
    stroke-width: 20;
    fill: none;
    transition: stroke 0.2s ease;

    /* Change the color when the sticky-nav-active class is applied */
    .sticky-nav-active & {
      stroke: #000000; /* Change to black when sticky */
    }
  }

  .popper {
    position: absolute;
    right: 0;
    z-index: 1;
    min-width: 160px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    transform-origin: top right;
  }

  .menu-list {
    list-style: none;
    margin: 0;
    padding: 8px 0;
  }

  .menu-item {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
`;

const LanguagePicker = ({ pageContext }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const containerRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleLanguageChange = (languageCode) => {
    const newLang = languages[languageCode];
    if (newLang) {
      const newPrefix = newLang.code === 'en' ? '' : `/${newLang.code}`;
      const currentPath =
        window.location.pathname.replace(
          pageContext.langPathPrefix,
          newPrefix
        ) || '/';
      const newUrl = `${currentPath}${window.location.search}${window.location.hash}`;

      // Use a full redirect to change the language while preserving the anchor
      window.location.href = newUrl;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const activeLanguages = Object.values(languages).filter(
    (lang) => lang.active
  );

  return (
    <LanguagePickerContainer ref={containerRef}>
      <button
        ref={anchorRef}
        onClick={handleToggle}
        className="icon-button"
        aria-haspopup="true"
        aria-expanded={open}
        aria-controls={open ? 'language-menu' : undefined}
      >
        <svg
          className="globe-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 420 420"
        >
          <path d="m 226.19946,16.656571 a 473.96004,333.37897 0 0 1 0,387.232509 M 59,333.21514 a 260,260 0 0 1 302,0 M 197.17143,14.79354 a 477.24462,335.68933 0 0 0 0,389.91607 M 209,15 a 195,195 0 1 0 2,0 z m 1,0 V 405 M 405,210 H 15 M 59,92.669492 a 260,260 0 0 0 302,0 M 361,330" />
        </svg>
      </button>
      {open && (
        <div className="popper" role="menu" id="language-menu">
          <ul className="menu-list">
            {activeLanguages.map((lang) => (
              <li
                key={lang.code}
                className="menu-item"
                onClick={() => {
                  handleLanguageChange(lang.code);
                  handleClose();
                }}
              >
                {lang.lng_title_native}
              </li>
            ))}
          </ul>
        </div>
      )}
    </LanguagePickerContainer>
  );
};

export default LanguagePicker;
